import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData= [
  {
    title: 'QR Code TShirt',
    text: '$29.99',
  },
  {
    title: 'QR Code Sticker',
    text: '$9.99',
  },
  {
    title: 'Street Team',
    text: '$19.99',
  },
  {
    title: 'Ad Service',
    text: '$49.99',
  },
  {
    title: 'WebApp',
    text: '$49.99',
  },
];


const Features = () => (
  <div className="kordoc__features section__padding" id="features">
    <div className="kordoc__features-heading">
      <h1 className="gradient__text">The Future is Now and You Just Need to Realize It. Step into Future Today. Let's Make it Happen.</h1>
    </div>
    <div className="kordoc__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
      <h1 className="gradient__text"><a href="https://homeless-amethyst-panther.reetail.store/"><b>Finalize Purchase HERE!</b></a></h1>
    </div>
    </div>
);
export default Features;