import React from 'react';


import { Footer, Possibility, Features, Gallery, Kordoc, Header } from './containers';
import { CTA, Navbar } from './components';
import './App.css';

const App = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <Header />
      </div>
      <Kordoc />
      <Features />
      <Gallery />
      <Possibility />
      <CTA />
      <Footer />
      </div>
  );
}
export default App