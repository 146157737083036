import React from 'react';
import Feature from '../../components/feature/Feature';
import './kordoc.css';

const Kordoc = () => (
  <div className="kordoc__wkordoc section__margin" id="wkordoc">
    <div className="kordoc__wkordoc-feature">
      <Feature title="What is Kor Doc?" text="This movement pushes innovation while focusing on protecting our environment a step at a time. Innovation is tied to technology allowing us to customize to our desired path forward, Kor Doc helps integrate modern practices with a classic approach. Kor Doc has options that could lead to a community of others seemlessly exchanging information while promoting growth.
      
      The total package Kor Doc prefers to implement with clients like restaurants involves: Converting physical menus into a digital Web Application, the digital Web Application will be hosted by a QR code that could be place on a sticker or shirt. Those QR code products can be presented to the public for advertising. The company's advertising can then be layered by face-to-face marketing as well as advertising revenue from display advertisements." />
    </div>
    <div className="kordoc__wkordoc-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p className="p__opensans"><a href="https://twitter.com/KorDocNews">Explore the Library</a></p>
    </div>
    <div className="kordoc__wkordoc-container">
      <Feature title="QR Code Shirt" text="The production of a shirt(s) featuring your custom QR Code via Spreadshirt (Comparable to Teespring or Redbubble)" />
      <Feature title="QR Code Sticker" text="Smart routing with SmartPhones, a modern technique to connect with your products and services" />
      <Feature title="Street Team" text="Earn a Customer, market to your customer via QR code visit in various scenarios" />
      <Feature title="Advertisement Services" text="Modern Advertising, apply for display advertisements to earn additional earnings" />
      <Feature title="WebApp" text="User Interface Services, experts utilizes modern technology (Canva, React and Chat-GPS) to meet client acceptance criteria" />
    </div>
  </div>
);

export default Kordoc;
