import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="kordoc__cta">
    <div className="kordoc__cta-content">
      <p>Web Application based on your Acceptance Criteria</p>
      <h3>Begin your Web App Project Today!</h3>
    </div>
    <div className="kordoc__cta-btn">
      <button type="button" onClick={() => window.location.href="https://kordoc.github.io/formsubmit/"}>Get Started</button>
  </div>
</div>
);

export default CTA
