import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="kordoc__possibility section__padding" id="possibility">
    <div className="kordoc__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="kordoc__possibility-content">
      <h1 className="gradient__text">Referral <br /> Program</h1>
      <p>Want an opportunity to make cash or receive a free meal? Please refer a person, business or entity to Kor Doc. Reach out to our <a href="https://kordoc.github.io/formsubmit/"><b>Support Team</b></a> to be given guidelines on how the process works.  </p>
    </div>
    <div className="kordoc__possibility-content">
      <h1 className="gradient__text">Testimonials</h1>
      <p>"Kor Doc produced a webapp and some QR Code T-Shirts for promotion, customer service, and innovative marketing. We were able to advertise our brand face to face with just a scan of our shirts!" <b>TC's Fully Loaded</b> </p>
      <p>"Thanks to Kor Doc we were about to change our way of thinking, adding a QR Code sticker to the back of my cell phone feels better than using a business card. The QR Code scan can show our clients exactly what we provide on the spot." <b>Murdock Consulting Group</b> </p>
    </div>
  </div>
);

export default Possibility