import React from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { images } from '../../constants';
import './gallery.css';

const galleryImages = [images.gallery01, images.gallery02, images.gallery03, images.gallery04, images.gallery05];

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if(direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  }

  return (
    <div className="kordoc__gallery-images">
      <div className="kordoc__gallery-images_container" ref={scrollRef}>
        {galleryImages.map((image, index) => (
          <div className="kordoc__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
          <img src={image} alt="gallery" />
          <BsInstagram className="gallery__image-icon" />
          </div>       
          ))}
        </div>
        <div className="kordoc__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
        </div>
)

}
export default Gallery;